class Nav {
    constructor() {
        this.burger = document.querySelector(".navbar-burger");
        this.menu = document.querySelector(".navbar-menu");
    }

    init() {
        if (this.burger && this.menu) {
            this.runBurger();
            this.runDropdown();
        }
    }

    runBurger() {
        this.burger.addEventListener("click", (e) => {
            this.burger.classList.toggle("is-active");
            this.menu.classList.toggle("is-active");
        });
    }

    runDropdown() {
        const mobileNav = document.querySelector(".navbar-menu");

        // set eventlistener on mobileNav
        mobileNav.addEventListener("click", (e) => {
            //check if click on submenu (.has-dropdown)

            const hasDropdown = e.target.classList.contains("js-dropdown")
                ? true
                : e.target.parentElement.classList.contains("js-dropdown");

            // if true take data-attr & toggle is-hidden on content
            if (hasDropdown) {
                //select correct element
                const clickedMenu = e.target.classList.contains("js-dropdown")
                    ? e.target
                    : e.target.parentElement;

                const submenus = document.querySelectorAll("[data-sub]");

                // loop through submenus
                for (let submenu of submenus) {
                    submenu.classList.add("is-hidden-touch");
                    if (clickedMenu.dataset.menu === submenu.dataset.sub) {
                        submenu.classList.remove("is-hidden-touch");
                    }
                }
            }
        });
    }
}

export default new Nav();
