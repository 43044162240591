class InpageNav {
    constructor() {
        this.navLinks = document.querySelectorAll(".js-nav-button");
        this.tabContainers = document.querySelectorAll(".js-tab-container");
        this.articles = document.querySelectorAll(".js-observer");
    }

    init() {
        if (this.navLinks.length > 0) {
            this.runObserver();
            this.runTabEventlistener();
        }
    }

    runObserver() {
        const options = {
            rootMargin: "-49% 0px -49% 0px",
        };

        const changeActive = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const articleId =
                        entry.target.querySelector("h1,h2,h3,h4").id;
                    this.navLinks.forEach((button) => {
                        let href = button.hash.slice(1);
                        if (articleId === href) {
                            button.classList.add("is-active");
                            const tab = document.querySelector(
                                ".js-current-headline > a"
                            );
                            const text = button.innerHTML;
                            tab.innerHTML = text;
                        } else {
                            button.classList.remove("is-active");
                        }
                    });
                }
            });
        };
        const articleObserver = new IntersectionObserver(changeActive, options);
        this.articles.forEach((article) => {
            articleObserver.observe(article);
        });
    }

    runTabEventlistener() {
        this.tabContainers.forEach((container) => {
            container.addEventListener("click", (e) => {
                const clickedTabArea = e.target.closest(".js-tabs");
                if (clickedTabArea) {
                    const tabContent =
                        container.querySelector(".js-tab-content");
                    const dropdown = container.querySelector(".js-dropdown");
                    tabContent.classList.toggle("is-hidden");
                    dropdown.classList.toggle("rotate");
                }
            });
        });
    }
}

export default new InpageNav();
